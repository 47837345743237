/* used in ByQuickCode.jsx */

.code-input-container {
  width: 100%;
}

.code-input-character {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
}

.error {
  border-color: red;
}
