/* came with the lib */

.react-calendar {
  background: none;
  border: none;
}

.react-calendar__month-view__days__day--weekend,
button.react-calendar__tile {
  color: rgba(255, 255, 255, 0.4);
  border: 1px solid #33505b;
}

.react-calendar__month-view__weekdays__weekday {
  color: rgba(255, 255, 255, 0.6);
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__navigation > * {
  color: white;
}

button.react-calendar__tile--active {
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

button.react-calendar__tile--hasActive {
  background: rgba(255, 255, 255, 0.4);
  color: white;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: rgba(255, 255, 255, 0.4);
}

button.react-calendar__tile--now {
  background-color: #2e64c9;
  color: white;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: none;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: rgba(255, 255, 255, 0.4);
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
  color: black;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: #e6e6e6;
}
