@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter.woff2) format("woff2");
}

body {
  margin: 0;
  font-family: "Inter", "Roboto", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: radial-gradient(
    84.77% 84.77% at 50% 15.23%,
    rgb(45, 131, 187) 0%,
    rgb(15, 75, 115) 100%
  );
}

body *::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body *::-webkit-scrollbar-track {
  background-color: #106594;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}

body *::-webkit-scrollbar-thumb {
  background-color: #29aaff;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: clip;
}
